

.react-calendar{
  border-radius:15px;
  padding-left:30px;
  padding-right:30px;
  padding-bottom: 15px;
  padding-top:15px;
  background: none;
}


.react-calendar__tile--now{
  background-color: #fab87b;
}

.react-calendar__tile--active{
  background-color: var(--bs-primary);
}

.react-calendar__tile--active:hover{
  background-color: var(--bs-primary);
}
