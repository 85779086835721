
.timer-btn-anim-enter {
  opacity: 1;
  transform: translateX(-100%);
}

.timer-btn-anim-enter-active {
  opacity: 1;
  transform: translateX(100%);
  transition: transform 500ms;
}

.timer-btn-anim-exit {
  opacity: 1;
  transform: translateX(-100%);
}

.timer-btn-anim-exit-active {
  opacity: 1;
  transform: translateX(100%);
  transition: transform 500ms;
}


.oldtask-edit-anim-enter {
  opacity: 0.6;
  animation: bounce 500ms ease-out;
}

.oldtask-edit-anim-enter-active {
  opacity: 1;
}

.oldtask-edit-anim-exit {
  opacity: 1;
}

.oldtask-edit-anim-exit-active {
  opacity: 0.01;
  transform: translateY(-100%);
  transition: 500ms ease-in;
}

@media (max-width: 768px) {
  .anim-window {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .anim-window {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
}



.table-entries {
  width: 100%;
  padding: 0;
  margin: 0;
  transition: 500ms ease-in;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.table-entries-enter {
  opacity: 0.6;
  animation: bounce 500ms ease-out;
}

.table-entries-enter-active {
  opacity: 1;
}

.table-entries-exit {
  opacity: 1;
}

.table-entries-exit-active {
  opacity: 0.01;
  transform: translateY(-100%);
  transition: 500ms ease-in;
}

@keyframes bounce {
  0% {
      transform: translateY(-100%);
  }

  50% {
      transform: translateY(0);
  }

  65% {
      transform: translateY(-5%);
  }

  100% {
      transform: translateY(0);
  }
}
