

.table-date {
  text-align: left;
}

.old-task-table {
  padding: 0;
  margin: 0;
  width: 100%;
}

.old-task {
  padding: 15px 0 15px 15px;
  border: 1px solid var(--bs-accent);
  margin: 0;
  background-color: var(--bs-bg-accent);
}

.old-task .segment {
  border-right: 1px solid #ccc;
  @include center;
}


.table-head {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  height: 50px;
}

.table-total-header {
  border-radius: 15px 15px 0 0;
  color: var(--bs-body-background);
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  height: 40px;

}

.table-total-header time {
  width: 75%;
}

.invoice-task{
  margin:0;
  padding-left:15px;
  padding-right:0px;
  padding-top:0px;
  padding-bottom:0px;
  border: 1px solid var(--bs-accent);
  margin: 0;
  background: var(--bs-body-background);
}

.invoice-task div {
  border-right: 1px solid var(--bs-accent);
  @include center;
}

.fixed-width-table {
  width: 100%;
  border-collapse: collapse;
}

.fixed-width-table th, .fixed-width-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.fixed-width-table th {
  width: 200px; /* You can adjust the width as needed */
  background-color: #f2f2f2;
}

.fixed-width-table td {
  width: 200px; /* Match the width of th */
}

.fixed-width-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.fixed-width-table tr:hover {
  background-color: #ddd;
}