

$custom-map: (
  "custom-color": #990000
);



$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1600px
        );