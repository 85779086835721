
// Mixin variant for active components with hover and focus states.
@mixin active-component-variant($component,
  $background,
  $border,
  $color: if(contrast-ratio($background, $white) > 3, $white, $black),
  $hover-background: if($color ==$color-contrast-light, shade-color($background, $btn-hover-bg-shade-amount), tint-color($background, $btn-hover-bg-tint-amount)),
  $hover-border: if($color ==$color-contrast-light, shade-color($border, $btn-hover-border-shade-amount), tint-color($border, $btn-hover-border-tint-amount)),
  $hover-color: color-contrast($hover-background),
  $active-background: if($color ==$color-contrast-light, shade-color($background, $btn-active-bg-shade-amount), tint-color($background, $btn-active-bg-tint-amount)),
  $active-border: if($color ==$color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: color-contrast($active-background),
  $disabled-background: $background,
  $disabled-border: $border,
  $disabled-color: color-contrast($disabled-background)) {
  --#{$prefix}#{$component}-color: #{$color};
  --#{$prefix}#{$component}-bg: #{$background};
  --#{$prefix}#{$component}-border-color: #{$border};
  --#{$prefix}#{$component}-hover-color: #{$hover-color};
  --#{$prefix}#{$component}-hover-bg: #{$hover-background};
  --#{$prefix}#{$component}-hover-border-color: #{$hover-border};
  --#{$prefix}#{$component}-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
  --#{$prefix}#{$component}-active-color: #{$active-color};
  --#{$prefix}#{$component}-active-bg: #{$active-background};
  --#{$prefix}#{$component}-active-border-color: #{$active-border};
  --#{$prefix}#{$component}-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}#{$component}-disabled-color: #{$disabled-color};
  --#{$prefix}#{$component}-disabled-bg: #{$disabled-background};
  --#{$prefix}#{$component}-disabled-border-color: #{$disabled-border};
}

@mixin component-bg-variant($color, $color-rgb){
  color: if(contrast-ratio($color, $white) > 3, $white, $black);
  background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);

}

@mixin component-color-variant($color-rgb){
  color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);

}

// Base theme colors from bootstrap to fill in missing pieces
$base-theme-colors: (
  "primary": $blue,
  "secondary": $gray-600,
  "success": $green,
  "info": $cyan,
  "warning": $yellow,
  "danger": $red,
  "light": $gray-100,
  "dark": $gray-900
);

//For each theme in the map, create a new data-bs-theme group
@each $theme, $theme-data in $themes {


  [data-bs-theme="#{$theme}"] {
    $theme-map: map-get($theme-data, "colors");
    $body-color: map-get($theme-data, "body-color");
    $border-color: map-get($theme-data, "border-color");
    $body-bg: map-get($theme-data, "body-bg");
    $accent: map-get($theme-data, "accent");
    $bg-accent: mix(color-contrast($body-bg), $body-bg, 5%);
    $body-emphasis-color: $accent;
    --#{$prefix}body-color: #{$body-color};
    --#{$prefix}border-color: #{$border-color};
    --#{$prefix}body-bg: #{$body-bg};
    --#{$prefix}accent: #{$accent};
    --#{$prefix}bg-accent: #{$bg-accent};
    --#{$prefix}text-on-accent: #{mix(color-contrast($bg-accent), $body-color, 15%)};

    //reconstruct theme colors
    $theme-colors: map-merge($base-theme-colors, $theme-map);

    //set the color params again
    $primary: map-get($theme-colors, "primary");
    $secondary: map-get($theme-colors, "secondary");
    $success: map-get($theme-colors, "success");
    $info: map-get($theme-colors, "info");
    $warning: map-get($theme-colors, "warning");
    $danger: map-get($theme-colors, "danger");
    $light: map-get($theme-colors, "light");
    $dark: map-get($theme-colors, "dark");

    //set the generated color maps from given values
    @each $color, $value in $colors {
      --#{$prefix}#{$color}: #{$value};
    }
  
    @each $color, $value in $grays {
      --#{$prefix}gray-#{$color}: #{$value};
    }
  
    @each $color, $value in $theme-colors {
      --#{$prefix}#{$color}: #{$value};
    }
  
    @each $color, $value in $theme-colors-rgb {
      --#{$prefix}#{$color}-rgb: #{$value};
    }
  
    @each $color, $value in $theme-colors-text {
      --#{$prefix}#{$color}-text-emphasis: #{$value};
    }
  
    @each $color, $value in $theme-colors-bg-subtle {
      --#{$prefix}#{$color}-bg-subtle: #{$value};
    }
  
    @each $color, $value in $theme-colors-border-subtle {
      --#{$prefix}#{$color}-border-subtle: #{$value};
    }


    // Create table variants with pastel colors
    $table-variants: (
      "primary": shift-color($primary, $table-bg-scale),
      "secondary": shift-color($secondary, $table-bg-scale),
      "success": shift-color($success, $table-bg-scale),
      "info": shift-color($info, $table-bg-scale),
      "warning": shift-color($warning, $table-bg-scale),
      "danger": shift-color($danger, $table-bg-scale),
      "light": $light,
      "dark": $dark,
    );


  //RGB map of theme colors
  $theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value") !default;

  // Set any variable calculated from default colors again, in case they are referenced again
  $primary-text-emphasis: shade-color($primary, 60%) !default;
  $secondary-text-emphasis: shade-color($secondary, 60%) !default;
  $success-text-emphasis: shade-color($success, 60%) !default;
  $info-text-emphasis: shade-color($info, 60%) !default;
  $warning-text-emphasis: shade-color($warning, 60%) !default;
  $danger-text-emphasis: shade-color($danger, 60%) !default;
  $primary-bg-subtle: tint-color($primary, 80%) !default;
  $secondary-bg-subtle: tint-color($secondary, 80%) !default;
  $success-bg-subtle: tint-color($success, 80%) !default;
  $info-bg-subtle: tint-color($info, 80%) !default;
  $warning-bg-subtle: tint-color($warning, 80%) !default;
  $danger-bg-subtle: tint-color($danger, 80%) !default;
  $primary-border-subtle: tint-color($primary, 60%) !default;
  $secondary-border-subtle: tint-color($secondary, 60%) !default;
  $success-border-subtle: tint-color($success, 60%) !default;
  $info-border-subtle: tint-color($info, 60%) !default;
  $warning-border-subtle: tint-color($warning, 60%) !default;
  $danger-border-subtle: tint-color($danger, 60%) !default;

  $body-secondary-color: rgba($body-color, .75) !default;
  $body-tertiary-color: rgba($body-color, .5) !default;
  $link-color: $accent !default;
  $link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
  $component-active-bg: $primary !default;
  $focus-ring-color: rgba($primary, $focus-ring-opacity) !default;
  $focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;

  $table-striped-color: $table-color !default;
  $table-active-color: $table-color !default;
  $table-hover-color: $table-color !default;
  $input-btn-focus-color: $focus-ring-color !default;

  $form-check-input-focus-border: $input-focus-border-color !default;
  $form-check-input-checked-color: $component-active-color !default;
  $form-check-input-checked-bg-color: $component-active-bg !default;
  $form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
  $form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
  $form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
  $form-check-input-indeterminate-color: $component-active-color !default;
  $form-check-input-indeterminate-bg-color: $component-active-bg !default;
  $form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
  $form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;
  $form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
  $form-switch-focus-color: $input-focus-border-color !default;
  $form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;
  $form-switch-checked-color: $component-active-color !default;
  $form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
  $input-group-addon-color: $input-color !default;
  $input-group-addon-border-color: $input-border-color !default;
  $form-select-color: $input-color !default;
  $form-select-disabled-border-color: $input-disabled-border-color !default;
  $form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;
  $form-select-border-color: $input-border-color !default;
  $form-select-focus-border-color: $input-focus-border-color !default;
  $form-select-focus-box-shadow: 0 0 0 $form-select-focus-width $input-btn-focus-color !default;
  $form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow !default;
  $form-range-thumb-active-bg: tint-color($component-active-bg, 70%) !default;
  $form-range-thumb-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
  $form-file-button-color: $input-color !default;
  $form-feedback-valid-color: $success !default;
  $form-feedback-invalid-color: $danger !default;
  $form-feedback-icon-valid-color: $form-feedback-valid-color !default;
  $form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
  $form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
  $form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
  $form-valid-color: $form-feedback-valid-color !default;
  $form-valid-border-color: $form-feedback-valid-color !default;
  $form-invalid-color: $form-feedback-invalid-color !default;
  $form-invalid-border-color: $form-feedback-invalid-color !default;
  $nav-link-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
  $nav-pills-link-active-color: $component-active-color !default;
  $navbar-light-icon-color: rgba($body-color, .75) !default;
  $navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
  $navbar-light-brand-color: $navbar-light-active-color !default;
  $navbar-light-brand-hover-color: $navbar-light-active-color !default;
  $navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
  $navbar-dark-brand-color: $navbar-dark-active-color !default;
  $navbar-dark-brand-hover-color: $navbar-dark-active-color !default;
  $dropdown-divider-bg: $dropdown-border-color !default;
  $dropdown-link-hover-color: $dropdown-link-color !default;
  $dropdown-link-active-color: $component-active-color !default;
  $dropdown-dark-border-color: $dropdown-border-color !default;
  $dropdown-dark-link-color: $dropdown-dark-color !default;
  $dropdown-dark-link-active-color: $dropdown-link-active-color !default;
  $pagination-active-color: $component-active-color !default;
  $pagination-active-border-color: $component-active-bg !default;
  $pagination-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
  $accordion-button-focus-border-color: $input-focus-border-color !default;
  $accordion-icon-color: $body-color !default;
  $accordion-icon-active-color: $primary-text-emphasis !default;
  $accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
  $accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !default;
  $popover-header-color: $headings-color !default;
  $popover-arrow-color: $popover-bg !default;
  $toast-header-border-color: $toast-border-color !default;
  $modal-footer-border-color: $modal-header-border-color !default;
  $alert-color-scale: 40% !default;
  $progress-bar-bg: $primary !default;
  $list-group-item-color-scale: 40% !default;
  $list-group-active-color: $component-active-color !default;
  $list-group-active-border-color: $list-group-active-bg !default;
  $carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !default;
  $carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$carousel-control-color}'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !default;
  $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
  $offcanvas-border-color: $modal-content-border-color !default;
  $code-color: $pink !default;



  // Maps from the variables above
  $theme-colors-text: (
    "primary": $primary-text-emphasis,
    "secondary": $secondary-text-emphasis,
    "success": $success-text-emphasis,
    "info": $info-text-emphasis,
    "warning": $warning-text-emphasis,
    "danger": $danger-text-emphasis,
    "light": $light-text-emphasis,
    "dark": $dark-text-emphasis,
    ) !default;
    
  $theme-colors-bg-subtle: (
    "primary": $primary-bg-subtle,
    "secondary": $secondary-bg-subtle,
    "success": $success-bg-subtle,
    "info": $info-bg-subtle,
    "warning": $warning-bg-subtle,
    "danger": $danger-bg-subtle,
    "light": $light-bg-subtle,
    "dark": $dark-bg-subtle,
    ) !default;
    
  $theme-colors-border-subtle: (
    "primary": $primary-border-subtle,
    "secondary": $secondary-border-subtle,
    "success": $success-border-subtle,
    "info": $info-border-subtle,
    "warning": $warning-border-subtle,
    "danger": $danger-border-subtle,
    "light": $light-border-subtle,
    "dark": $dark-border-subtle,
    ) !default;

  //Dark mode changes
  @if $enable-dark-mode {
    $theme-colors-text-dark: (
      "primary": $primary-text-emphasis-dark,
      "secondary": $secondary-text-emphasis-dark,
      "success": $success-text-emphasis-dark,
      "info": $info-text-emphasis-dark,
      "warning": $warning-text-emphasis-dark,
      "danger": $danger-text-emphasis-dark,
      "light": $light-text-emphasis-dark,
      "dark": $dark-text-emphasis-dark,
      ) !default;

    $theme-colors-bg-subtle-dark: (
      "primary": $primary-bg-subtle-dark,
      "secondary": $secondary-bg-subtle-dark,
      "success": $success-bg-subtle-dark,
      "info": $info-bg-subtle-dark,
      "warning": $warning-bg-subtle-dark,
      "danger": $danger-bg-subtle-dark,
      "light": $light-bg-subtle-dark,
      "dark": $dark-bg-subtle-dark,
      ) !default;
      
    $theme-colors-border-subtle-dark: (
      "primary": $primary-border-subtle-dark,
      "secondary": $secondary-border-subtle-dark,
      "success": $success-border-subtle-dark,
      "info": $info-border-subtle- dark,
      "warning": $warning-border-subtle-dark,
      "danger": $danger-border-subtle-dark,
      "light": $light-border-subtle-dark,
      "dark": $dark-border-subtle-dark,
      ) !default;
  }

  $utilities-colors: $theme-colors-rgb !default;

  $utilities-text: map-merge($utilities-colors,
      ("black": to-rgb($black),
        "white": to-rgb($white),
        "body": to-rgb($body-color))) !default;
  $utilities-text-colors: map-loop($utilities-text, rgba-css-var, "$key", "text") !default;

  $utilities-text-emphasis-colors: (
    "primary-emphasis": var(--#{$prefix}primary-text-emphasis),
    "secondary-emphasis": var(--#{$prefix}secondary-text-emphasis),
    "success-emphasis": var(--#{$prefix}success-text-emphasis),
    "info-emphasis": var(--#{$prefix}info-text-emphasis),
    "warning-emphasis": var(--#{$prefix}warning-text-emphasis),
    "danger-emphasis": var(--#{$prefix}danger-text-emphasis),
    "light-emphasis": var(--#{$prefix}light-text-emphasis),
    "dark-emphasis": var(--#{$prefix}dark-text-emphasis)) !default;
    
  $utilities-bg: map-merge($utilities-colors,
      ("black": to-rgb($black),
        "white": to-rgb($white),
        "body": to-rgb($body-bg))) !default;
  $utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, "$key", "bg") !default;

  $utilities-bg-subtle: (
    "primary-subtle": var(--#{$prefix}primary-bg-subtle),
    "secondary-subtle": var(--#{$prefix}secondary-bg-subtle),
    "success-subtle": var(--#{$prefix}success-bg-subtle),
    "info-subtle": var(--#{$prefix}info-bg-subtle),
    "warning-subtle": var(--#{$prefix}warning-bg-subtle),
    "danger-subtle": var(--#{$prefix}danger-bg-subtle),
    "light-subtle": var(--#{$prefix}light-bg-subtle),
    "dark-subtle": var(--#{$prefix}dark-bg-subtle)) !default;
    
  $utilities-border: map-merge($utilities-colors,
      ("black": to-rgb($black),
        "white": to-rgb($white))) !default;
  $utilities-border-colors: map-loop($utilities-border, rgba-css-var, "$key", "border") !default;

  $utilities-border-subtle: (
    "primary-subtle": var(--#{$prefix}primary-border-subtle),
    "secondary-subtle": var(--#{$prefix}secondary-border-subtle),
    "success-subtle": var(--#{$prefix}success-border-subtle),
    "info-subtle": var(--#{$prefix}info-border-subtle),
    "warning-subtle": var(--#{$prefix}warning-border-subtle),
    "danger-subtle": var(--#{$prefix}danger-border-subtle),
    "light-subtle": var(--#{$prefix}light-border-subtle),
    "dark-subtle": var(--#{$prefix}dark-border-subtle)) !default;

  $utilities-links-underline: map-loop($utilities-colors, rgba-css-var, "$key", "link-underline") !default;

  @each $component in ("navbar", "offcanvas"){
    --#{$prefix}#{$component}-color: #{$body-color} !important;
    --#{$prefix}#{$component}-hover-color: #{$link-hover-color} !important;
    --#{$prefix}#{$component}-disabled-color: #{$accent} !important;
    --#{$prefix}#{$component}-active-color: #{$bg-accent} !important;
    --#{$prefix}#{$component}-brand-color: #{$body-color} !important;
    --#{$prefix}#{$component}-brand-hover-color: #{$accent} !important;
    --#{$prefix}#{$component}-toggler-border-color: #{$accent} !important;
  }












  // Some components share similar functionality and can be implemented with mixins
  @each $variant, $value in $theme-colors {
    $color-rgb: to-rgb($value);

  // Some components share similar functionality and can be implemented with mixins
  @each $component in ("btn", "alert") {
    .#{$component}-#{$variant} {
      @include active-component-variant($component, $value, $value );
    }
    .#{$component}-#{$variant}-soft {
      @include active-component-variant($component, $value, $value );
    }
  }

  .bg-#{$variant} {
    @include component-bg-variant($value, $color-rgb);
  }


  .text-bg-#{$variant} {
    @include component-bg-variant($value, $color-rgb);
  }


  .text-#{$variant} {
    @include component-color-variant($color-rgb);
  }


}

@each $variant, $value in $table-variants {

  $color-rgb: to-rgb($value);
  @include table-variant($variant, $value);
  @each $component in ("btn", "alert") {
    .#{$component}-#{$variant}-soft {
      @include active-component-variant($component, $value, $value );
    }
  }

  .bg-#{$variant}-soft {
    @include component-bg-variant($value, $color-rgb);
  }


  .text-bg-#{$variant}-soft {
    @include component-bg-variant($value, $color-rgb);
  }


  .text-#{$variant}-soft {
    @include component-color-variant($color-rgb);
  }
}








--#{$prefix}body-color: #{$body-color};
--#{$prefix}body-color-rgb: #{to-rgb($body-color)};
--#{$prefix}body-bg: #{$body-bg};
--#{$prefix}body-bg-rgb: #{to-rgb($body-bg)};

--#{$prefix}emphasis-color: #{$body-emphasis-color};
--#{$prefix}emphasis-color-rgb: #{to-rgb($body-emphasis-color)};

--#{$prefix}secondary-color: #{$body-secondary-color};
--#{$prefix}secondary-color-rgb: #{to-rgb($body-secondary-color)};
--#{$prefix}secondary-bg: #{$body-secondary-bg};
--#{$prefix}secondary-bg-rgb: #{to-rgb($body-secondary-bg)};

--#{$prefix}tertiary-color: #{$body-tertiary-color};
--#{$prefix}tertiary-color-rgb: #{to-rgb($body-tertiary-color)};
--#{$prefix}tertiary-bg: #{$body-tertiary-bg};
--#{$prefix}tertiary-bg-rgb: #{to-rgb($body-tertiary-bg)};
--#{$prefix}heading-color: #{$headings-color};

--#{$prefix}link-color: #{$link-color};
--#{$prefix}link-color-rgb: #{to-rgb($link-color)};
--#{$prefix}link-decoration: #{$link-decoration};

--#{$prefix}link-hover-color: #{$link-hover-color};
--#{$prefix}link-hover-color-rgb: #{to-rgb($link-hover-color)};

@if $link-hover-decoration !=null {
  --#{$prefix}link-hover-decoration: #{$link-hover-decoration};
}

--#{$prefix}code-color: #{$code-color};
--#{$prefix}highlight-bg: #{$mark-bg};

// scss-docs-start root-border-var
--#{$prefix}border-width: #{$border-width};
--#{$prefix}border-style: #{$border-style};
--#{$prefix}border-color: #{$border-color};
--#{$prefix}border-color-translucent: #{$border-color-translucent};

--#{$prefix}border-radius: #{$border-radius};
--#{$prefix}border-radius-sm: #{$border-radius-sm};
--#{$prefix}border-radius-lg: #{$border-radius-lg};
--#{$prefix}border-radius-xl: #{$border-radius-xl};
--#{$prefix}border-radius-xxl: #{$border-radius-xxl};
--#{$prefix}border-radius-2xl: var(--#{$prefix}border-radius-xxl); // Deprecated in v5.3.0 for consistency
--#{$prefix}border-radius-pill: #{$border-radius-pill};
// scss-docs-end root-border-var

--#{$prefix}box-shadow: #{$box-shadow};
--#{$prefix}box-shadow-sm: #{$box-shadow-sm};
--#{$prefix}box-shadow-lg: #{$box-shadow-lg};
--#{$prefix}box-shadow-inset: #{$box-shadow-inset};

// Focus styles
// scss-docs-start root-focus-variables
--#{$prefix}focus-ring-width: #{$focus-ring-width};
--#{$prefix}focus-ring-opacity: #{$focus-ring-opacity};
--#{$prefix}focus-ring-color: #{$focus-ring-color};
// scss-docs-end root-focus-variables

// scss-docs-start root-form-validation-variables
--#{$prefix}form-valid-color: #{$form-valid-color};
--#{$prefix}form-valid-border-color: #{$form-valid-border-color};
--#{$prefix}form-invalid-color: #{$form-invalid-color};
--#{$prefix}form-invalid-border-color: #{$form-invalid-border-color};
// scss-docs-end root-form-validation-variables

}
}