
.react-time-picker__clear-button{
  display: none;
}

.react-time-picker__clock-button{
  display:none;
}

.react-time-picker__wrapper{
  border-radius:3px;
  text-align:center;
  background:var(--bs-body-background);
  margin-left:40%;
  margin-right:40%;
  margin-top:8%;
}

.react-time-picker__clock-button__icon{
  display:none;
}

.react-time-picker__clock{
  border-radius:150px;
  height:200px;
  vertical-align: center;
  justify-content: center;
  inset: 110% auto auto auto !important;
}

@media (max-width: 768px) {
  .react-time-picker__clock{
    display:none;
  }
}

.react-time-picker{
  vertical-align: center;
  justify-content: center;
  width:100%;
}

.hidden {
  visibility: hidden;
  height: 0;
}
