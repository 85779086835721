
$minWidthThreshold: 1000;

.offcanvas {
  width: 100%; // Set the default width to 100%
  @media (min-width: #{$minWidthThreshold}px) {
    width: 20vw; // Apply 10vw if the viewport width is larger than x pixels
  }
}

.offcanvas.offcanvas-start{
  width: 100%; // Set the default width to 100%
  @media (min-width: #{$minWidthThreshold}px) {
    width: 20vw; // Apply 10vw if the viewport width is larger than x pixels
  }
}

.offcanvas-body {
  background-color: var(--bs-secondary-bg);
}

.offcanvas-title {
  margin-left: 1rem;
}

.offcanvas-li {
  height: 50px;
  border: 1px solid var(--bs-accent);
  padding: 5px;

  display: flex;
  justify-content: left;
  align-items: center;
  text-align: center;

}

.offcanvas-img {
  height: 40px;
  margin-right: 20px;
}


.sidebar{
  background:var(--bs-accent) !important;
  color:var(--bs-text-on-accent) !important;
}