@media print {
  /* Specify styles for printing */
  .container {
    max-width: 90% !important;
  }
}

@page {

  size: 1920px 900px;
}