
.current-task {
  padding: 15px;
  margin: 0;
  border: 1px solid var(--bs-accent);
  border-radius: 15px;
}

.current-task div {
  margin: 0;
  padding: 0;
  @include center;
}

.timer {
  font-size: large;
  font-weight: bold;
}

.test-flex-col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.test-flex-col-2 {
  display: flex;
  flex-direction: column;
}