

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin text-color{
  color: var(--bs-primary);
  &:hover{
      color: var(--bs-accent);
  }
}

svg path {
  fill: currentColor;
}

.text-color{
  @include text-color;
}

.bg-accent{
  color: var(--bs-text-on-accent);
  background-color: var(--bs-accent);
}

.clickable {
  cursor: pointer;
  transition: 0.2s;
}

.clickable:hover {
  color: darken($primary, 10%);
}

.table-row {
  border-width: 3px;
}

.nav-username {
  margin-right: 15px;
  text-decoration: none;
}

.thumbnail {
  height: 50px;
  border-radius: 50px;
}


.shadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.half-up {
  margin-top: -75px;
}

.buffer-20 {
  height: 20px;
}

.buffer-5vh {
  height: 5vh;
}

.btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: auto;
}


@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dashboard {
  margin-left: 10vw;
  margin-right: 10vw;
  width: 80vw;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}


.app {
  padding-left: 10vw;
  padding-right: 10vw
}



.rjust {
  text-align: right;
  justify-content: right;
  width: 100%;
}

.animated {
  animation-duration: 0.2s;
  transition-duration: 0.2s;
}


.btn-timer {
  width: 100px;
}

.modal-dialog {
  max-width:40%;
  padding:20px;
  /* You can use percentage or pixels */
}

.split-page-container{
  min-height:75vh;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--bs-accent);
    opacity: 1;
    /* Firefox */
}

a{
  color: var(--bs-accent);
}

.autocomplete-container {
  position: relative;
}


//class that is only visible when the page is printed
.print-only{
  display: none;
}

@media print{
  .print-only{
    display: flex;
  }
  .no-print{
    display: none;
  }
}
.suggestion-container{
  position:absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;      
  display: flex;
  flex-direction: column; /* Stack children as rows */
}

.autocomplete-suggestion {
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--bs-accent);
  border-radius: 0 0 4px 4px;
  background-color: #fff;
}

.autocomplete-suggestion:hover{
  background-color: var(--bs-accent);
  cursor: pointer;
}

.autocomplete-suggestion-text {
  padding: 8px;
  color: var(--bs-accent);
  color: black;
}

// .autocomplete-suggestion-text:hover {
//   background-color: var(--bs-text-on-accent);
// }

// Utility class for nav buttons

.text-b-nav {
  font-weight: bold;
  color: black;
  text-align: left;

  transition: opacity 250ms ease-in;
}

.text-b-nav:hover {
  background-color: white;
  color: black;
  opacity: 0.9;
}